import React from 'react'
import classes from '../styles/About.module.css';
import { useSelector } from 'react-redux';

const Aboutme = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState);
    const personDetails = portfolioDetails?.personDetails[0];
    const themeModeFrmRdx = useSelector(state => state?.themeModeState?.themeMode);


    return (
        <div className={classes.box} id="about" style={{color:themeModeFrmRdx ? 'black' : 'aliceblue'}}>
            <span className={classes.head}>ABOUT ME</span>
            {/* <h2 className={classes.heading}>Who Am I?</h2> */}
            <div className={classes.About}>
                <p>Hi there.! I am <b>{personDetails?.first_name}&nbsp;{personDetails?.last_name}</b> and I'm currently working as <b>{personDetails?.current_designation}</b> at <b>{personDetails?.current_company}</b>. I Graduated in Bachelor of Tecnologies with <b>Computer Science &amp; Engineering</b> at Rajiv Gandhi University of <b>IIIT Nuzvid</b> Andra Pradesh.</p>
                <p className={classes.br}>I am interested in learning new technologies. I always want to be a part in new trends. I was very passionate about coding more over I like coding challenges. I am a versatile coder in most of the coding languages like JAVA, Python, C and JavaScript.</p>
            </div>
        </div>
    )
}

export default Aboutme