import { API_URL } from "../api-utils/apiConfigs";

export const CHATBOT_LOGIN = API_URL + '/chatbotuserlogin';
export const TODO_ADD = API_URL + '/addTodo';
export const GET_ALLTODOS = API_URL + '/getAllTodos';
export const DELETE_TODO = API_URL + '/deleteTodo';
export const UPDATE_TODO_ITEM = API_URL+ '/updatetodoitem';
export const GET_MYDEVELOPMENTS = API_URL + '/myDevelopmentData';
// export const GET_PORTFOLIO_DETAILS = API_URL + '/getmyspacePortfolioDetails';
export const GET_PORTFOLIO_DETAILS = API_URL + '/getmyspaceblogdetails';
export const SAVE_FEEDBACK_DETAILS = API_URL + '/saveFeedbackDetails';