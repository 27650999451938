import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './CSS/FooterPage.css';
import { MdCode, MdEdit } from 'react-icons/md';
import { BsCodeSlash, BsGit, BsGithub, BsLinkedin } from 'react-icons/bs';
import { RiProfileFill } from 'react-icons/ri';
import { SiGmail } from 'react-icons/si';

const FooterPage = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState);
    const themeModeFrmRdx = useSelector(state => state?.themeModeState?.themeMode);

    const personDetails = portfolioDetails?.personDetails[0];

    return (
        personDetails &&
        <div className='container-fluid' style={{ background: themeModeFrmRdx ? 'aliceblue': 'black' }}>
            {/* {JSON.stringify(personDetails)} */}
            <Container className='' style={{ background: themeModeFrmRdx ? 'aliceblue': 'black' }}>
                <div className='footerPage'>
                    <div className='row justify-content-center' style={{color: themeModeFrmRdx ? 'black': 'lightblue'}}>
                        <div className='col-md-3'></div>
                        <div className='col-md-6 '>
                            <div className='p-2 mt-5'>
                                <div className='m-1 mb-4'>
                                    <BsCodeSlash className='codeIcon' />
                                </div>
                                <div className='m-3'>
                                    Developed by {personDetails?.first_name}
                                </div>
                                <div className='m-1' style={{ fontSize: "0.78rem" }}>
                                    As a developer maintaining portfolio is essential. Above specified information is all about me.
                                    Feel free to contact me for any queries. Thank you :)
                                </div>
                                <div className='m-1 p-4 contactIcons'>
                                    <span className='btn'><BsLinkedin onClick={()=>window.open(personDetails?.linkedin_url , '_blank')} className='' /></span>
                                    <span className='btn'><BsGithub onClick={()=>window.open(personDetails?.git_url , '_blank')} className='' /></span>
                                    <span className='btn'><RiProfileFill onClick={()=>window.open('#' , '_blank')} className='' /></span>
                                    <span className='btn'><SiGmail onClick={()=>window.open('mailto:'+personDetails?.email_id , '_blank')} className='' /></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'></div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default FooterPage