import './App.css';

import React, { useEffect, useState } from 'react';
import 'react-bootstrap';
import { Provider } from 'react-redux';
import LoadingSpinner from './components/common/commonComps/LoadingSpinner';
import FooterPage from './components/main/FooterPage';
import { reduxStore } from './components/redux/Store';
import RouterPage from './components/routes/RouterPage';
import apiServices from './components/utils/service-calls/apiServices';

const store = reduxStore;

const App = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [portfolioDetails, setPortfolioDetails] = useState(null);
  // const [themeMode, setThemeMode] = useState(false);
  // const themeMode = useSelector(state => state?.themeModeState);
  const themeMode = store?.getState().themeModeState?.themeMode;
  // const themeMode = store?.getState().themeModeState?.themeMode;

  const getTokenFromLogin = () => {
    reduxStore?.dispatch({ type: 'SHOW_SPINNER' })
    let token = localStorage.getItem("token");
    if (token !== null && token !== "" && token !== undefined) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
    reduxStore?.dispatch({ type: 'HIDE_SPINNER' });
  }


  useEffect(() => {
    getTokenFromLogin();
  }, [loggedIn, themeMode])

  // const dispatch = useDispatch();

  const getPortfolioDetails = () => {
    apiServices.getPortfolioDetails().then((res) => {
      if (res?.data?.status == 'success') {
        console.log('res data --> ', res?.data?.data);
        reduxStore?.dispatch({ type: 'PORTFOLIO_DETAILS', payload: res?.data?.data })
        setPortfolioDetails(res?.data?.data);
      }
    })
  }
  useEffect(() => {
    getPortfolioDetails();
  }, [])


  return (
    <Provider store={store}>
      <div className="App"
        style={{ backgroundColor: themeMode ? 'white' : 'black', margin: '0', }}
      >
        
        {
          portfolioDetails &&
          <div data-bs-spy="scroll" data-bs-target="#navbarstyled" data-bs-offset="0" tabindex="0">
            <RouterPage />
          </div>
        }

        <LoadingSpinner />
        <FooterPage />
      </div>
    </Provider>
  )
}

export default App;

{/* {loggedIn ? <RouterPage /> : <WelcomePage />} */ }
{/* // <Login setLoggedIn={setLoggedIn} />} */ }
{/* {loggedIn ? 'hi there ' : <Login setLoggedIn={setLoggedIn} />} */ }

// window.onscroll = () => scrollFunction();

// const scrollFunction = () => {
//   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
//     document.getElementById("navbar").style.top = "0";
//     document.getElementById("navbar").style.position = 'sticky';
//   } else {
//     document.getElementById("navbar").style.top = "-50px";
//   }

// import React, { Component } from 'react';
// import { HashRouter } from 'react-router-dom';
// import './App.css';
// import About from './components/elements/About';
// import CarouselImages from './components/elements/CarouselImages.js';
// import Certify from './components/elements/Certify';
// import Education from './components/elements/Education';
// import Experience from './components/elements/Experience';
// import Interest from './components/elements/Interest';
// import Sidebar from './components/elements/Sidebar';
// import Skills from './components/elements/Skills';
// import Works from './components/elements/Works';

// class App extends Component {

//   render() {
//     return (
//       <div className='container'>
//         <HashRouter>
//           <div className='row vh-100' style={{fontFamily:'ubuntu'}}>
//             <div className="col-md-3 p-0 m-0" >
//               <nav className="navbar  navbar-expand-lg p-0 m-0" >
//                 <Sidebar />
//               </nav>
//             </div>
//             <div className='col-md-9 content vh-100 p-0 m-0' >
//               <CarouselImages />
//               <div className=''>
//                 <About />
//                 <Interest />
//                 <Experience />
//                 <Skills/>
//                 <Education/>
//                 <Certify/>
//                 <Works/>
//               </div>
//             </div>
//           </div>
//         </HashRouter>
//       </div>
//     );
//   }
// }

// export default App;
