import { CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const LoadingSpinner = ({ stateFronLocal }) => {
    const loaderState = useSelector(state => state?.spinner);
    return (
        <div>
            {/* {
                loaderState?.showSpinner ? <CircularProgress /> : null
            }*/}
            {
                stateFronLocal || loaderState?.showSpinner ?
                    <div>
                        <div class="loader">
                        </div>
                        <div className='loadingText'>Please wait upto 30 sec it's a free server.....</div>
                    </div>

                    : null
            }
            {/* {
                stateFronLocal || loaderState?.showSpinner ?
                    <div className="ringSpin">Loading..!
                        <span></span>
                    </div>
                    : null
            } */}


        </div>
    )
}

export default LoadingSpinner