import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { MdDownload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { TypeAnimation } from 'react-type-animation';
import personPhoto from '../images/raj11.jpg';
import '../styles/HomePage.css';

const HomePage = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState);
    const personDetails = portfolioDetails?.personDetails[0];
    
    return (
        <Row >
            <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                {/* <div className='loader'> */}
                <div className='d-flex justify-content-center  personPhoto' style={{ width: '100%', height: "100%" }}>
                    <img src={personPhoto} alt='personPhoto' className='shadow' style={{ display: "block", alignSelf: 'center' }} />
                </div>
                {/* </div> */}
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7} className=''>
                <div className='titleCol'>
                    <div className='d-flex justify-content-start'>
                        <div className='display-6  headerText' style={{ fontFamily: '' }}>
                            I'm, <span className='fw-bold'></span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-start'>
                        <div className='subHeadText' style={{ fontFamily: 'darlington' }}>
                            <h1>{personDetails?.first_name}</h1>
                        </div>
                    </div>

                    <div className='d-flex designationText'>
                        <TypeAnimation
                            sequence={[`${personDetails?.person_designation}`, 500]}
                            speed={20}
                            style={{ fontSize: '2rem', fontWeight: 'lighter', color: 'grey' }}
                            repeat={Infinity} />
                    </div>
                    <div className='d-flex m-0 mt-2 '>
                        <Button className='btn btn-secondary btn-sm' onClick={() => window.open(personDetails?.resume_url, '_blank')} ><MdDownload className='me-2' />Resume</Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default HomePage