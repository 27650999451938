import React from 'react';
import classes from '../styles/Experience.module.css';
import { useSelector } from 'react-redux';
import { MdJavascript, MdWork, MdWorkspacePremium } from 'react-icons/md';

const Experience = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState);
    const themeModeFrmRdx = useSelector(state => state?.themeModeState?.themeMode);

    return (
        <div className={classes.box}>
            {/* {JSON.stringify(portfolioDetails?.workedCompanies)} */}
            <span className={classes.head}>MY WORK EXPERIENCE</span>
            <section className={classes.container}>
                <div className={classes.container_content}>
                    <div className={classes.row}>
                        <div className={classes.row_md_12}>
                            <div className={classes.timeline_centered}>
                                {
                                    portfolioDetails?.workedCompanies?.map((comp, compIndex) => {
                                        return <article className={`${classes.timeline_entry}`} key={compIndex} >
                                            <div className={`${classes.timeline_icon}`} style={{ backgroundColor: comp?.color_code }}>
                                                <MdWork style={{color:themeModeFrmRdx ? 'darkslategrey': 'purple'}} />
                                            </div>
                                            <div className={`${classes.label}`} style={{backgroundColor: themeModeFrmRdx ? '#f2f3f7': '#1B2631', color:themeModeFrmRdx? 'black':'lightblue'}}>
                                                <div className='d-flex justify-content-between m-0 p-0'>
                                                    <h2 style={{color:themeModeFrmRdx? 'black': 'steelblue'}}>{comp?.company_name} </h2>
                                                    <span>{comp?.from_date} to {comp?.to_date}</span>
                                                </div>
                                                <div className='d-flex justify-content-start m-0 p-0'>{comp?.designation}</div>
                                                <ol reversed type='I' prefix=''>
                                                    {
                                                        portfolioDetails?.workedProjects?.filter(item => item?.company_code === comp?.company_code)?.map((item, index) => {
                                                            let responsibilitesOfProject = item?.responsibilities?.split('.');
                                                            return (
                                                                <li key={index} className={`${classes.projectListItem}`} >
                                                                    <div className='d-flex justify-content-start fw-bold m-1'>{item?.project_name}</div>
                                                                    <div className='d-flex justify-content-between m-1'>
                                                                        <div className={`${classes.propsOfProject}`}>
                                                                            Client:<span> {item?.client_name}</span>
                                                                        </div>
                                                                        <div className={`${classes.propsOfProject}`}>
                                                                            Role:<span> {item?.role_name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${classes.propsOfProject} d-flex justify-content-start m-1`}>Description </div>
                                                                    <div className={`d-flex justify-content-start m-1 ${classes.projectDesc}`}>
                                                                        <p className='text-left m-0' align='justify'>
                                                                            {item?.project_desc}
                                                                        </p>
                                                                    </div>
                                                                    <div className={`${classes.propsOfProject} d-flex justify-content-start m-1`}>Responsibilities </div>
                                                                    <div className={`${classes.projectDesc} d-flex justify-content-start m-1`}>
                                                                        <ul className={`${classes.responsibilityItems}`}>
                                                                            {
                                                                                responsibilitesOfProject?.map((res, resIndex) => (
                                                                                    (res != ' ' && res != '') &&
                                                                                    <li key={resIndex} className={`${classes.projectDesc}`} style={{ textAlign: 'left' }}>{res}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                            </div>

                                        </article>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Experience


{/**

 <article className={classes.timeline_entry} key={compIndex}>
                                                                    <div className='d-flex justify-content-start fw-bold'>Project {portfolioDetails?.workedProjects?.filter(item => item?.company_code === comp?.company_code)?.length - index}</div>
                                                                    <div >{item?.client_name}</div>
                                                                    <div className={''}>
                                                                        <div className='d-flex justify-content-between m-0 p-0'>
                                                                            <div>{item?.project_name} </div>
                                                                            <span>{comp?.from_date} to {comp?.to_date}</span> 
                                                                            </div>
                                                                            <div className='d-flex justify-content-start m-0 p-0'>{comp?.designation}</div>
                                                                        </div>
                                                                    </article>

 <article className={classes.timeline_entry}>
                                    <div className={`${classes.timeline_icon}`} style={{ backgroundColor: '#FAD7A0' }}>
                                        </div>
                                        <div className={classes.label}>
                                            <h2 >Java Application Developer <span>Nov-2020 to Present</span></h2>
                                            <p>
                                                I was a <b>Java Developer</b> at <b><a href='https://www.taxilla.com' target='_blank' rel="noopener noreferrer">Taxilla IT Solutions</a></b> worked on java related applications.
                                                As an Apps Developer I have been working here from Nov-2020 to till now. I was worked on Java and it’s supportive language groovy to develope effective web applications for client requirement. These are web server based applications to generate custom Invoices for clients.
                                                We used Dynamic web servers to maintain applications.
                                            </p>
                                        </div>
                                    </article>
                                    <article className={classes.timeline_entry}>
                                        <div className={classes.timeline_icon} style={{ backgroundColor: '#EBDEF0' }}>
                                             <SiPython style={{color: 'steelblue', fontWeight: 'bold'}}/> 
                                        </div>
                                        <div className={classes.label}>
                                            <h2 >Python Script Analyst<span> Sep-2019 to May-2020</span></h2>
                                            <p>
                                                I was a <b>Python Script Analyst</b> at <b><a href='https://www.linkedin.com/company/gss-infotech/?originalSubdomain=in' target='_blank' rel="noopener noreferrer">GSS Infotech</a></b> for python cloud project, in this project for 9 months. It is a US based client named “SUNGARD AS”. Analyzing the modules capable of the latest python3, identified the compatible modules and made changes accordingly. Documented the process of migration and shared to all applicable projects of other teams.
                                            </p>
                                        </div>
                                    </article>
                                    <article className={classes.timeline_entry}>
                                        <div className={`${classes.timeline_icon}`} style={{ backgroundColor: '#A9DFBF' }}>
                                            <SiJava style={{color: 'blue', fontWeight: 'bold'}}/> 
                                        </div>
                                        <div className={classes.label}>
                                            <h2 >Java Trainee <span >March-2019 to Aug-2019</span></h2>
                                            <p>
                                                I was joined here as a fresher on Java Technology at <b><a href='http://ddugky.gov.in/content/try-logic-soft-solutions-ap-pvt-ltd-6' target='_blank' rel="noopener noreferrer">Trylogic Soft Solutions</a></b>. I worked here as a trainee on <b>Java and J2EE</b> for web application development in their projects. We worked on servlets and JDBC applications. Successfully completed internship with good score.
                                            </p>
                                        </div>
                                    </article>
                                    <article className={classes.timeline_entry}>
                                        <div className={`${classes.timeline_icon}`} style={{ backgroundColor: '#A9DFBF' }}>
                                             <SiJava style={{color: 'blue', fontWeight: 'bold'}}/> 
                                        </div>
                                        <div className={classes.label}>
                                            <h2 >Java Trainee <span >March-2019 to Aug-2019</span></h2>
                                            <p>
                                                I was joined here as a fresher on Java Technology at <b><a href='http://ddugky.gov.in/content/try-logic-soft-solutions-ap-pvt-ltd-6' target='_blank' rel="noopener noreferrer">Trylogic Soft Solutions</a></b>. I worked here as a trainee on <b>Java and J2EE</b> for web application development in their projects. We worked on servlets and JDBC applications. Successfully completed internship with good score.
                                            </p>
                                        </div>
                                    </article>
*/}
