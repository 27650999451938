import { combineReducers } from "redux";
import { SpinnerReducer } from "./utils-reducers/SpinnerReducer";
import { ThemeModeReducer } from "./utils-reducers/ThemeModeReducer";
import { PortfolioReducer } from "./main-reducers/PortfolioReducer";
import { FieldPropsReducer } from "./resume-reducers/FieldPropsReducer";

const rootReducer = combineReducers({
    spinner: SpinnerReducer,
    themeModeState: ThemeModeReducer,
    portfolioState: PortfolioReducer,
    fieldPropState: FieldPropsReducer
});

export default rootReducer;