import React from 'react';
import classes from '../styles/Skills.module.css';
import { useSelector } from 'react-redux';


const Skills = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState);
    const skillSet = portfolioDetails?.skillSet;
    const skillSetKeys = portfolioDetails?.skillsKeys;
    const mySkills = portfolioDetails?.mySkills;
    const themeModeFrmRdx = useSelector(state => state?.themeModeState?.themeMode);

    return (
        <div className={classes.box} id="skills">
            <span className={classes.head}>MY SKILLS</span>
            {/* <h2 className={classes.heading}>These are some of my Skills</h2> */}

            <div className="row">
                {
                    mySkills?.slice(0,10)?.map((skillItem, skillIndex) => (
                        <div className="col-md-6 animate-box fadeInLeft animated" data-animate-effect="fadeInLeft">
                            <div className="progress-wrap">
                                <h3 className='pb-0 mb-2'>{skillItem?.skill_name}</h3>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow={`${skillItem?.skill_value}`} aria-valuemin="0" aria-valuemax="100" style={{ width: `${skillItem?.skill_value}%`, backgroundColor: skillItem?.skill_style }}>
                                        <span>{skillItem?.skill_value}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {/* {JSON.stringify(skillSet?.map(item => item?.primary_skills))}
            {JSON.stringify(Object.keys(skillSet[0]))} */}
            <div className='row'>
                {
                    skillSetKeys?.map((skillKey, skillIndex) => (
                        <div className="col-md-4   mt-5">
                            <div className={`card text-secondary  ${classes.card1} ${''}`} style={{ backgroundColor: themeModeFrmRdx ? 'aliceblue' : 'lightblue' }}>
                                <div className="card-body">
                                    <h5 className="card-title text-dark" >
                                        {skillKey?.key_label}
                                    </h5>
                                    <div className='row'>
                                        {skillSet?.map(item => (
                                            item[skillKey?.key_name?.trim()] != '-' && <div className='col-md-6 p-1'>
                                                <p className='card-text text-small'>{item[skillKey?.key_name]}</p>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>



        </div>
    )
}

export default Skills

{/**

<div className=" row">
                    <div className="col-md-3">
                        <div className={`card text-secondary mt-5 ${classes.card1}`}>
                            <div className="card-body">
                                <h5 className="card-title text-dark" >
                                    Programming &amp; Scripting
                                </h5>
                                <p className="card-text text-small" >
                                    JavaScript &amp; ES6, Java &amp; J2EE, Python, HTML5 &amp; CSS3, Groovy
                                    Script, C &amp; C++, XML, YAML, XSLT.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={`card text-secondary mt-5 ${classes.card1}`}>
                            <div className="card-body">
                                <h5 className="card-title text-dark" >
                                    Cloud
                                </h5>
                                <p className="card-text text-small" >
                                    AWS: EC2|Lambda|SNS|VPC, Heroku, Jenkins, Docker, Kubernetes,
                                    MongoDB Atlas, Git Hub, Git Hub Pages, Jira.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={`card text-secondary mt-5 ${classes.card1}`}>
                            <div className="card-body">
                                <h5 className="card-title text-dark" >
                                    Tools &amp; OS
                                </h5>
                                <p className="card-text text-small" >
                                    Window (7-11), macOS, Linux: Ubuntu|Zorin|Red
                                    Hat|Fedora|Mint|Raspbian|Noobs. VSCode, Atom, Eclipse, PyCharm,
                                    STS, Photoshop 7.0,
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className={`card text-secondary mt-5 ${classes.card1}`}>
                            <div className="card-body">
                                <h5 className="card-title text-dark" >
                                    Frameworks &amp; Libraries
                                </h5>
                                <p className="card-text text-small" >
                                    React+Redux, Node+Express, Spring, Django, Boostrap, React
                                    Bootstrap, JSX, JSP, Servlets, NumPy, Pandas.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
*/}